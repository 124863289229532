import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DatePickerComponent } from "../../../../shared/ui/DatePickerComponent";
import Line from "../../../../shared/ui/Line";
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks/useAdverseEventsProduodopa";
import { useDisabled } from "../useDisabled";
import { convertBoolValue, convertBoolValueHandler } from "../helper";
import { useEffect } from "react";

export const productsElements = ["Lek", "Pompa", "Zestaw infuzyjny", "Adapter fiolki", "Strzykawka", "Akcesorium do noszenia", "Część zamienna"];
const reasonElements = ["Osoba zgłaszająca nie posiada informacji na temat numeru serii, ponieważ (np. opakowanie produktu zostało wyrzucone)", 
    "Osoba zgłaszająca odmówiła podania informacji na temat numeru serii",
    "Nie udało się skontaktować z osobą zgłaszającą w celu uzyskania informacji na temat numeru serii",
    "Dane kontaktowe osoby zgłaszającej były niedostępne",
    "Gabinet lekarski nie prowadzi rejestracji numerów serii",
    "Inny powód (należy podać jaki)"
];
const isAvailableSampleElements = ["Tak", "Nie (Jeżeli Nie, należy zaznaczyć powód)"]
const notAvailableSampleReasonElements = ["Nie zachowano / wyrzucono",
    "Nadal w użyciu",
    "Inny powód (należy podać jaki)"
]

const EventInfo = observer(({fullForm}) => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {eventInfo} = adverseEventsProduodopaStore;

    const {
        isDisabled : isDisabledBatteryCoverSerialNumber,
        changeDisabledSingle : changeDisabledBatteryCoverSerialNumber
    } = useDisabled(true,  "sparePart.batteryCoverSerialNumber", adverseEventsProduodopaStore.eventInfoOnChange)

    const {
        isDisabled : isDisabledBatterySerialNumber,
        changeDisabledSingle : changeDisabledBatterySerialNumber,
    } = useDisabled(true,  "sparePart.batterySerialNumber", adverseEventsProduodopaStore.eventInfoOnChange)

    const {
        isDisabled : isDisabledWearableAccessorySerialNumber,
        changeDisabledSingle : changeDisabledWearableAccessorySerialNumber,
    } = useDisabled(true,  "sparePart.wearableAccessorySerialNumber", adverseEventsProduodopaStore.eventInfoOnChange)

    const {
        isDisabled : isDisabledChargingKitSerialNumber,
        changeDisabledSingle : changeDisabledChargingKitSerialNumber
    } = useDisabled(true,  "sparePart.chargingKitSerialNumber", adverseEventsProduodopaStore.eventInfoOnChange)

    const {
        isDisabled : isDisabledReasonOther,
        changeDisabledSingle : changeDisabledReasonOther
    } = useDisabled(reasonElements[reasonElements.length - 1],  "reasonOther", adverseEventsProduodopaStore.eventInfoOnChange)

    const {
        isDisabled : isDisabledAvailableSample,
        changeDisabledSingle : changeDisabledAvailableSample
    } = useDisabled(false, "notAvailableSampleReasonOther", adverseEventsProduodopaStore.eventInfoOnChange, () => {
        adverseEventsProduodopaStore.eventInfoOnChange("notAvailableSampleReason", null)
    })
        
    const {
        isDisabled : isDisabledNotAvailableSampleReason,
        changeDisabledSingle : changeDisabledNotAvailableSampleReason
    } = useDisabled(notAvailableSampleReasonElements[notAvailableSampleReasonElements.length - 1], "notAvailableSampleReasonOther", adverseEventsProduodopaStore.eventInfoOnChange)
 

    useEffect(() => {
        if(!eventInfo.products.includes(productsElements[6])){
     
            changeDisabledBatteryCoverSerialNumber(false);
            changeDisabledBatterySerialNumber(false);
            changeDisabledWearableAccessorySerialNumber(false);
            changeDisabledChargingKitSerialNumber(false);
        }
    }, [eventInfo.products, changeDisabledBatteryCoverSerialNumber, changeDisabledBatterySerialNumber, changeDisabledWearableAccessorySerialNumber, changeDisabledChargingKitSerialNumber])
  
    return <Grid container pl={2} mt={4}>
            <Grid item xs={12} bgcolor="#071d49" p={'8px 16px'} >
                <Typography color='white' sx={{textTransform: 'uppercase'}}>Informacje o zdarzeniu: </Typography>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <Typography variant="subtitle1">Opis reklamacji</Typography>
                <Typography>Należy uwzględnić czynności, jakie wykonywał pacjent w momencie zdarzenia oraz opisać stosowaną terapię, w tym przepisane antybiotyki, wyniki badań diagnostycznych / laboratoryjnych:</Typography>
                <FormControl fullWidth>
                    <OutlinedInput
                                name="description"
                                value={eventInfo.description}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <Typography variant="subtitle1">Stan pacjenta / wynik zdarzenia:</Typography>
                <FormControl fullWidth>
                    <OutlinedInput
                                name="result"
                                value={eventInfo.result}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <Typography variant="subtitle1">Proszę zaznaczyć <u>wszystkie</u> produkty z wymienionych, poniżej, które mają związek z opisywanym zdarzeniem niepożądanym, a następnie uzupełnić części formularza dotyczące odpowiedniego produktu:</Typography>
                <FormGroup sx={{mt: 2}}
                    row
                    name="products"
                    onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange("products", e.target.value)}>
                    {productsElements.map(element => <FormControlLabel key={element} value={element}  control={<Checkbox checked={eventInfo.products.includes(element)} />} label={element}/>)}
                </FormGroup>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <Typography variant="subtitle1">Data rozpoczęcia terapii lekiem:</Typography>
                <DatePickerComponent
                    disableFuture
                    label="DD/MM/RRRR"
                    allowSameDateSelection
                    name="initiationDate"
                    value={eventInfo.initiationDate}
                    onChange={(value) => adverseEventsProduodopaStore.eventInfoOnChange("initiationDate", value)} />
            </Grid>

            {eventInfo.products.includes(productsElements[0]) && <Grid item xs={12} p={2} pt={5} sx={{border: '1px solid #9B9B9D', position: 'relative'}}  gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[0]}:</span></div>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer serii na fiolce:</Typography>
                    <OutlinedInput
                                name="drug.serialNumberVial"
                                value={eventInfo.drug.serialNumberVial}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value, fullForm)}/>
                </FormControl>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer seryjny na kartonie:</Typography>
                    <OutlinedInput
                                name="drug.serialNumberBox"
                                value={eventInfo.drug.serialNumberBox}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value, fullForm)}/>
                </FormControl>
                 <FormControl fullWidth>
                    <Typography variant="subtitle1">Data rozpoczęcia terapii lekiem:</Typography>
                    <DatePickerComponent
                            disableFuture
                            label="DD/MM/RRRR"
                            allowSameDateSelection
                            name="drug.drugTherapyStartDate"
                            value={eventInfo.drug.drugTherapyStartDate}
                            onChange={(value) => adverseEventsProduodopaStore.eventInfoOnChange("drug.drugTherapyStartDate", value, fullForm)} />
                </FormControl>
            </Grid>}

            {eventInfo.products.includes(productsElements[1]) &&<Grid item xs={12} p={2} pt={5} sx={{border: '1px solid #9B9B9D', position: 'relative'}}  gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[1]}:</span></div>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                                name="pump.serialNumber"
                                value={eventInfo.pump.serialNumber}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer seryjny pompy:</Typography>
                    <OutlinedInput
                                name="pump.serialNumberPump"
                                value={eventInfo.pump.serialNumberPump}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>}

            {eventInfo.products.includes(productsElements[2]) && <Grid item xs={12} p={2} pt={5} sx={{border: '1px solid #9B9B9D', position: 'relative'}} gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[2]}:</span></div>
                <div>
                    <Typography variant="subtitle1">Data użycia zestawu infuzyjnego:</Typography>
                    <DatePickerComponent
                        disableFuture
                        label="DD/MM/RRRR"
                        allowSameDateSelection
                        name="infusion.usedDate"
                        value={eventInfo.infusion.usedDate}
                        onChange={(value) => adverseEventsProduodopaStore.eventInfoOnChange("infusion.usedDate", value)} />
                </div>
                <div>
                    <Typography variant="subtitle1">Data, kiedy zestaw infuzyjny był wymieniany:</Typography>
                    <DatePickerComponent
                        disableFuture
                        label="DD/MM/RRRR"
                        allowSameDateSelection
                        name="infusion.replacedDate"
                        value={eventInfo.infusion.replacedDate}
                        onChange={(value) => adverseEventsProduodopaStore.eventInfoOnChange("infusion.replacedDate", value)} />
                </div>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                        name="infusion.serialNumber"
                        value={eventInfo.infusion.serialNumber}
                        onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>}
 
            {eventInfo.products.includes(productsElements[3]) && <Grid item xs={12} p={2} pt={5} sx={{border: '1px solid #9B9B9D', position: 'relative'}}  gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[3]}:</span></div>
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                            name="vialAdapter.serialNumber"
                            value={eventInfo.vialAdapter.serialNumber}
                            onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>}

            {eventInfo.products.includes(productsElements[5]) && <Grid item xs={12} p={2} pt={5} sx={{border: '1px solid #9B9B9D', position: 'relative'}}  gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[5]}:</span></div>
                
                <FormControl fullWidth>
                <Typography variant="subtitle1">Numer serii:</Typography>
                    <OutlinedInput
                                name="wearableAccessory.serialNumber"
                                value={eventInfo.wearableAccessory.serialNumber}
                                onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>}
           
            {eventInfo.products.includes(productsElements[6]) && <Grid item xs={12} p={2} pt={6} sx={{border: '1px solid #9B9B9D', position: 'relative'}}  gap={2} display='flex' flexDirection='column'>
                <div className="subMenuHeaderSmall"><span>{productsElements[6]}:</span></div>

                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Klapa baterii:</Typography>
                        <RadioGroup 
                            row
                            name="sparePart.isBatteryCover"
                            value={convertBoolValue(eventInfo.sparePart.isBatteryCover)}
                            onChange={(e) => {
                                adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledBatteryCoverSerialNumber(convertBoolValueHandler(e.target.value))
                            }}>
                            <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                            <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                    <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                            disabled={isDisabledBatteryCoverSerialNumber}
                            name="sparePart.batteryCoverSerialNumber"
                            value={eventInfo.sparePart.batteryCoverSerialNumber}
                            onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>

                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Bateria:</Typography>
                        <RadioGroup row
                            name="sparePart.isBattery"
                            value={convertBoolValue(eventInfo.sparePart.isBattery)}
                            onChange={(e) => {
                                adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledBatterySerialNumber(convertBoolValueHandler(e.target.value))
                            }}>
                            <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                            <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                    <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                            disabled={isDisabledBatterySerialNumber}
                            name="sparePart.batterySerialNumber"
                            value={eventInfo.sparePart.batterySerialNumber}
                            onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Akcesorium do noszenia:</Typography>
                        <RadioGroup row
                            name="sparePart.isWearableAccessory"
                            value={convertBoolValue(eventInfo.sparePart.isWearableAccessory)}
                            onChange={(e) => {
                                adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledWearableAccessorySerialNumber(convertBoolValueHandler(e.target.value))
                            }}>
                            <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                            <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                    <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                             disabled={isDisabledWearableAccessorySerialNumber}
                             name="sparePart.wearableAccessorySerialNumber"
                             value={eventInfo.sparePart.wearableAccessorySerialNumber}
                             onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>

                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Zestaw do ładowania:</Typography>
                        <RadioGroup row  
                            name="sparePart.isChargingKit"
                            value={convertBoolValue(eventInfo.sparePart.isChargingKit)}
                            onChange={(e) =>{
                                adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledChargingKitSerialNumber(convertBoolValueHandler(e.target.value))
                            }}>
                            <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                            <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                    <Typography variant="subtitle1">Numer serii: </Typography>
                    <OutlinedInput
                            disabled={isDisabledChargingKitSerialNumber}
                            name="sparePart.chargingKitSerialNumber"
                            value={eventInfo.sparePart.chargingKitSerialNumber}
                            onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>}

            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
                <Typography variant="subtitle1">Jeżeli numer serii jest nieznany, należy zaznaczyć, z jakiego powodu: </Typography>
                <div>
                    <FormControl fullWidth>
                        <RadioGroup 
                            name="reason"
                            value={eventInfo.reason}
                            onChange={(e) => {
                                adverseEventsProduodopaStore.eventInfoOnChange("reason", e.target.value);
                                changeDisabledReasonOther(e.target.value) }}>
                                {
                                    reasonElements.map(element => <FormControlLabel mt={1} value={element} control={<Radio />} label={element} />)
                                }
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                        <OutlinedInput
                             disabled={isDisabledReasonOther}
                             name="reasonOther"
                             value={eventInfo.reasonOther}
                             onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>
                    </div>
            </Grid>

            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
                <Typography variant="subtitle1">Dostępna próbka reklamacyjna:  </Typography>
                <div>
                <FormControl fullWidth>
            
                    <RadioGroup 
                            name="isAvailableSample"
                            value={convertBoolValue(eventInfo.isAvailableSample, isAvailableSampleElements)}
                            onChange={(e) =>{
                                adverseEventsProduodopaStore.eventInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value, isAvailableSampleElements));
                                changeDisabledAvailableSample(convertBoolValueHandler(e.target.value, isAvailableSampleElements))
                            }}>
                                {isAvailableSampleElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Radio />} label={element} />)}
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <RadioGroup sx={{pl: 6, pt: 1}} 
                            name="notAvailableSampleReason"
                            value={eventInfo.notAvailableSampleReason}
                            onChange={(e) =>{
                                adverseEventsProduodopaStore.eventInfoOnChange("notAvailableSampleReason", e.target.value);
                                changeDisabledNotAvailableSampleReason(e.target.value)
                            }}>
                            {notAvailableSampleReasonElements.map(element => <FormControlLabel key={element} disabled={isDisabledAvailableSample} mt={1} value={element} control={<Radio />} label={element} /> )}
                        </RadioGroup>
                </FormControl>
                <FormControl fullWidth sx={{pl: 12, pt: 1}}>
                     <OutlinedInput
                        disabled={isDisabledAvailableSample || isDisabledNotAvailableSampleReason}
                        name="notAvailableSampleReasonOther"
                        value={eventInfo.notAvailableSampleReasonOther}
                        onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange("notAvailableSampleReasonOther", e.target.value)}
                        />
                </FormControl>
                <Line/>
                <FormControlLabel name="patientAgreement" checked={eventInfo.patientAgreement} onChange={(e) => adverseEventsProduodopaStore.eventInfoOnChange("patientAgreement", e.target.checked)} control={<Checkbox />} label="Pacjent wyraził zgodę na przesłanie próbki, AbbVie podejmie kroki w celu otrzymania próbek" />
              
                <Typography mt={1}><Box component='span' sx={{color: '#ED6C02'}}>Uwaga: </Box>Należy przechowywać nieużywane próbki reklamacyjne do czasu ich wysłania do firmy AbbVie lub Dostawcy. </Typography>
                </div>
            </Grid>
    </Grid>
});

export default EventInfo;