export const initMainInfo = {
  applicationReceiptDate: null, // date
  notificationDate: null, // date
  institutionId: null,
  complaintTypes: [], // array
};

export const patient = {
  initials: null, // int
  phone: null, // string
  email: null, // string
  birthdate: null, // date
  gender: null, // string
};
export const initComplaintInfo = {
  fullName: null, // string
  phone: null, // string
  email: null, // string
  fromComes: [], // string
  fromComesOther: null, // string
  isResponseToPatient: null, // bool
  patient: {
    ...patient,
    participationInPostMarketStudy: null, // string
    number: null, // string
  },
};

export const initDoctorInfo = {
  specializations: [], // string
  specializationsOther: null, // string
  isDoctorContact: null, // bool
  fullName: null, // string
  phone: null, // string
  email: null, // string
  street: null, // string
  city: null, // string
  voivodeship: null, // int
  postcode: null, // string
};

export const initEventInfo = {
  description: null, // string
  result: null, // string
  products: [], // string[]
  initiationDate: null, // date
  patient: { ...patient },
  drug: {
    serialNumberVial: null, // string
    serialNumberBox: null, // string
  },
  pump: {
    serialNumber: null, // string
    serialNumberPump: null, // string
  },
  infusion: {
    usedDate: null, // date
    replacedDate: null, // date
    serialNumber: null, // string
  },
  wearableAccessory: {
    serialNumber: null, // string
  },
  vialAdapter: {
    serialNumber: null, // string
  },
  sparePart: {
    isBatteryCover: null, // bool
    batteryCoverSerialNumber: null, // string
    isBattery: null, // bool
    batterySerialNumber: null, // string
    isWearableAccessory: null, // bool
    wearableAccessorySerialNumber: null, // string
    isChargingKit: null, // bool
    chargingKitSerialNumber: null, // string
  },
  reason: null, // string
  reasonOther: null, // string
  isAvailableSample: null, // bool
  notAvailableSampleReason: null, // string
  notAvailableSampleReasonOther: null, // string
  patientAgreement: null, // bool
};

export const initDrugInfo = {
  receivedComplaint: null, // string
  problemNoticed: null, // string
  vialsChecked: null, // string
  drugStorage: null, // string
  vialDefects: [], // array
  drugDefects: [], // array
};

export const initPumpInfo = {
  isPumpError: null, // bool
  errorDetails: [], // array
  errorDetailsOther: null, // string
  physicalProblems: [], // array
  functionalProblems: [], // array
  softwareProblems: [], // array
  softwareProblemsExplanation: null, // string
  softwareProblemsOther: null, // string
  solutions: [], // array,
  isAvailableReplacementPump: null, // bool
  availableReplacementPumpHelped: null, // string
};

// initial object for infusion info
export const initInfusionInfo = {
  components: [], // array
  defects: [], // array
  defectsOther: null, // string
  defectsJustification: null, //string
  leakingConnections: [], // array
  leakingConnectionsOther: null, // string
  isAdapterChecked: null, // bool
  problemNoticed: null, // string
  problemVisible: null, // string
  solutions: [], // array
  solutionsOther: null, // string
};

export const initAdapterInfo = {
  defects: [], // array
  leakingConnections: [], // array
  leakingConnectionsOther: null, // string
  isAppearanceProblem: null, // bool
  appearanceProblemDescription: null, // string
  isAdapterChecked: null, // bool
  solution: null, // string
  functionalDifficulty: null, // string
};

export const initSyringeInfo = {
  defects: [], // array
  leakingConnections: [], // array
  leakingConnectionsOther: null, // string
  isAppearanceProblem: null, // bool
  appearanceProblemDescription: null, // string
  isSyringeChecked: null, // bool
  date: null, // date
  isProblemVisible: null, // bool
  solution: null, // string
  functionalDifficulty: null, // string
};

export const initAccessoriesInfo = {
  components: [], // array
  componentsOther: null, // string
  defects: [], // array
  isAppearanceProblem: null, // bool
  appearanceProblemDescription: null, // string
  isAccessoriesChecked: null, // bool
  date: null, // date
  isProblemVisible: null, // bool
  solution: null, // string
  functionalDifficulty: null, // string
};

export const initSparePartInfo = {
  components: [], // array
  componentsOther: null, // string
  batteryCoverProblems: [], // array
  batteryProblems: [], // array
  chargingProblems: [], // array
  chargingProblemsOther: null, // string
  isAppearanceProblem: null, // bool
  appearanceProblemDescription: null, // string
  isSparePartChecked: null, // bool
  date: null, // date
  isProblemVisible: null, // bool
  solution: null, // string
  functionalDifficulty: null, // string
};

export const initEvent = {
  date: null, // date
  dateStart: null, // date
  dateEnd: null, // date
  description: null, // string
  result: null, // string
  causalRelationship: null, // string
};

export const initSimpleDrug = {
  serialNumberVial: null, // string
  serialNumberBox: null, // string
  drugTherapyStartDate: null, // string
};

export const initDrug = {
  title: null, // string
  dateStart: null, // date
  dateEnd: null, // date
  dose: null, // string
  routeOfAdministration: null, // string
  indication: null, // string
};

export const initMedicalHistory = {
  description: null, // string
  dateStart: null, // date
  dateEnd: null, // date
};
export const initPartTwoInfo = {
  events: [], //array
  drugsTakenAtSameTime: null, // string
  drugs: [], //array
  medicalHistoriesExists: null, // string
  medicalHistories: [], // array
  allergy: null, // string
  drug: initSimpleDrug, // object
};

export const setDeepValue = (element, path, value) => {
  const keys = path.split(".");
  const lastKey = keys.pop();

  const deepRef = keys.reduce((acc, key) => {
    if (!acc[key]) acc[key] = {};
    return acc[key];
  }, element);
  deepRef[lastKey] = value;

  return element;
};

export const convertBoolValueHandler = (value, values = ["Tak", "Nie"]) => {
  return value !== null ? value === values[0] : null;
};

export const convertBoolValue = (value, values = ["Tak", "Nie"]) => {
  return value !== null ? (value === true ? values[0] : values[1]) : null;
};

export const checkboxMultiChange = (values, newValue, checkExtra) => {
  let newValues = [...values];

  if (checkExtra && !checkExtra.includes(newValue)) {
    return newValues;
  }

  if (newValues.includes(newValue)) {
    newValues = newValues.filter((part) => part !== newValue);
  } else {
    newValues.push(newValue);
  }

  return newValues;
};
