import DoctorInfo from "./DoctorInfo"
import ComplaintInfo from "./partOne/ComplaintInfo"
import EventInfo from "./partOne/EventInfo"
import ProductsInfo from "./partOne/ProductsInfo"

const PartOne = ({fullForm}) => {

    return <>
      <ComplaintInfo fullForm={fullForm}/>
      <DoctorInfo />
      <EventInfo  fullForm={fullForm}/>
      <ProductsInfo/>
    </>
}

export default PartOne